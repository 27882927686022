import BaseBean from "@/utils/BaseBean";
export interface IOraganizationBaseOtherInfos {
    corp:string
    dept:string
    position:string
    birthdate:string
    sex:string
}
export interface IBaseInfoForm{
    code:string
    nickName:string
    mobile:string
    email:string
    deptName:string
    userRoleName:string
}
export interface IModifyPwdForm {
    prePass:string
    newPass:string
    checkPass:string
}
export interface IChangePersonalInfosDataObj {
    utilInst:ChangePersonalInfosUtil
    refMap:Map<string,any>
    fullscreenLoading:boolean
    activeName:string
    avatarImage:string//用户头像图片路径
    canSave:boolean
    personalInfos:any
    oraganizationBaseOtherInfos:IOraganizationBaseOtherInfos
    baseInfoForm:IBaseInfoForm
    modifyPwdForm: IModifyPwdForm
    baseInfoFormRules:TFormRule
    modifyPwdFormRules:any
    showLeft:boolean
}
export default class ChangePersonalInfosUtil extends BaseBean{
    public dataObj: IChangePersonalInfosDataObj;
    constructor(proxy:any,dataObj:IChangePersonalInfosDataObj){
        super(proxy);
        this.dataObj=dataObj;
    }

    public resetForm(formName:string):void{
        if('modifyPwdForm'==formName){
            this.dataObj.refMap.get('modifyPwdFormRef').resetFields();
        }else{
            this.dataObj.baseInfoForm.nickName=this.dataObj.personalInfos.nickName;
            this.dataObj.baseInfoForm.mobile=this.dataObj.personalInfos.mobile;
            this.dataObj.baseInfoForm.email=this.dataObj.personalInfos.email;
        }
    }
    public checkNumD(str:string):boolean{
        // 判断字符串是否为数字和字母组合
        let zg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]*$/;
        if (!zg.test(str)) {
            return false;
        } else if(str.length<6 || str.length>12){
            return false;
        }
        return true;
    }
    public submitForm(formName:string):void{
        if('modifyPwdForm'==formName){
            this.dataObj.refMap.get('modifyPwdFormRef').validate((valid:BufferSource) => {
                valid ? this.doChangePwd() : false;
            });
        }else{
            this.dataObj.refMap.get('baseInfoFormRef').validate((valid:BufferSource) => {
                valid ? this.doChangeOtherInfo() : false;
            });
        }
    }
    public async doChangeOtherInfo():Promise<void>{
        this.dataObj.fullscreenLoading=true;
        let res = await this.utils.Api.changeOtherInfo(this.dataObj.baseInfoForm);
        if(res.result){
            this.utils.Tools.success();
        }
        this.dataObj.fullscreenLoading=false;
    }
    public async doChangePwd():Promise<void>{
        this.dataObj.fullscreenLoading=true;
        this.dataObj.modifyPwdForm.prePass = this.utils.UtilPub.encrypt(this.dataObj.modifyPwdForm.prePass);
        this.dataObj.modifyPwdForm.newPass = this.utils.UtilPub.encrypt(this.dataObj.modifyPwdForm.newPass);
        this.dataObj.modifyPwdForm.checkPass = this.utils.UtilPub.encrypt(this.dataObj.modifyPwdForm.checkPass);
        let res = await this.utils.Api.changePassword(this.dataObj.modifyPwdForm);
        if(res.result){
            this.utils.Tools.success();
            this.resetForm("modifyPwdForm");
        }
        this.dataObj.fullscreenLoading=false;
    }
}